<script>
import appConfig from "@/app.config";
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {},
  data() {
    return {
      masterPayroll: [],
      selectedPaymentMethod: {},
      deductions: [],
      paymentMethods: [],
      addAsset: [{}],
      currentAssets: [],
      employeeDeduction: {},
      salaryItems: [],
      data: {},
      totalSalary: 0,
      paymentMethod: {},
      totalDeductions: 0,
    };
  },
  methods: {
    getPaymentMethods() {
      this.http.get("cash-accounts/company-accounts").then((res) => {
        this.paymentMethods = res.data;
      });
    },
    changePaymentMethod() {
      this.paymentMethods.forEach((method) => {
        if (method.id == this.data.cash_account_id) {
          this.selectedPaymentMethod = method;
        }
      });
    },
    processPayModalData() {
      console.log(this.masterPayroll);
      let totalItems = 0,
        totalDeductionsInput = 0;
      this.masterPayroll.forEach((emp) => {
        if (emp.employeeDeduction) {
          totalDeductionsInput += parseFloat(emp.employeeDeduction);
        }
        totalItems += parseFloat(emp.total_balance);
      });
      let paidAmount = 0;
      this.masterPayroll.forEach((emp) => {
        if (emp.paid_balance) {
          paidAmount += parseFloat(emp.paid_balance);
        }
      });
      console.log({ paidAmount });
      this.totalDeductions = parseFloat(totalDeductionsInput);
      this.totalSalary =
        parseFloat(totalItems) - parseFloat(totalDeductionsInput) - parseFloat(paidAmount);
      this.paymentMethod = this.selectedPaymentMethod;
      console.log({ totalDeductions: this.totalDeductions });
    },
    getSalaryItems() {
      this.http.get("hr-salary-items").then((res) => {
        if (res.status) {
          this.salaryItems = res.data;
        }
      });
    },
    getMasterPayroll() {
      this.data.month = parseInt(this.data.date.split("-")[1]);
      this.data.year = this.data.date.split("-")[0];
      this.http
        .post("hr-employees/get-master-payroll", this.data)
        .then((res) => {
          if (res.status) {
            this.masterPayroll = res.data;
            console.log('lll',this.masterPayroll);
            console.log(res.status);
            console.log(res.data);
            if (res.data.length > 0) {
              document.querySelectorAll(".money-btn").forEach((element) => {
                element.removeAttribute("disabled");
              });
            }
          }
        });
    },
    changeDeduction(event, item) {
      console.log(item);
      if (event.target.value <= 0) {
        item.employeeDeduction = 0;
        return;
      }
      if (parseFloat(event.target.value) > parseFloat(item.total_loan)) {
        var data = {
          title: "popups.warning",
          template: `
          <h5>the deduction you insert it is more than the total  ?</h5>
        `,
        };
        this.popup.modal(data);
        item.employeeDeduction = 0;
        return;
      }
      item.employeeDeduction = parseFloat(event.target.value);
    },
    payMasterPayrollSalaries() {
      const emps = this.masterPayroll.map((emp) => {
        return {
          id: emp.id,
          deduction: emp.employeeDeduction ? emp.employeeDeduction : 0,
          total_balance: parseFloat(emp.total_balance),
        };
      });
      console.log(emps);
      this.http
        .post("hr-employees/pay-master-payroll", {
          month: this.data.month,
          year: this.data.year,
          emps,
          totalDeductions: this.totalDeductions,
        })
        .then((res) => {
          if (res.status) {
            document.querySelectorAll(".money-btn").forEach((element) => {
              element.setAttribute("disabled", true);
            });
            console.log("done");
          }
        });
    },
  },
  created() {
    this.getPaymentMethods();
    this.getSalaryItems();
  },
};
</script>

<template>
  <div class="row mb-4">
    <div class="col-md-10">
      <form @submit.prevent="getMasterPayroll()">
        <div class="row" style="align-items: flex-end">
          <div class="col">
            <label class="inline" for="toJobTitle">{{
              $t("payroll.master.date")
            }}</label>
            <input type="month" class="form-control" v-model="data.date" />
          </div>
          <div class="col">
            <label class="inline" for="toJobTitle">{{
              $t("payroll.master.payment_method")
            }}</label>
            <select
              v-model="data.cash_account_id"
              class="form-control fromto"
              id="toJobTitle"
              @change="changePaymentMethod()"
            >
              <option
                v-for="payment in paymentMethods"
                :key="payment"
                :value="payment.id"
                :selcted="paymentMethods[-1]"
              >
                {{ payment?.name }}
              </option>
            </select>
          </div>
          <div class="col">
            <button type="submit" class="btn btn-primary">
              {{ $t("popups.search") }}
            </button>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-2 align-self-end">
      <button
        type="submit"
        class="btn btn-primary money-btn"
        data-bs-toggle="modal"
        @click="processPayModalData()"
        data-bs-target="#payPayrollModal"
        v-if="masterPayroll.length > 0"
      >
        {{ $t("payroll.pay") }}
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table
              v-if="masterPayroll.length > 0"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              class="table table-centered table-nowrap align-middle"
              style="border: 2px solid gray"
            >
              <thead>
                <tr class="text-center" style="border: 2px solid gray">
                  <th style="border: 2px solid gray" scope="col" colspan="2">
                    {{ $t("payroll.master.descr") }}
                  </th>
                  <th
                    style="border: 2px solid gray"
                    scope="col"
                    :colspan="salaryItems.length"
                  >
                    {{ $t("payroll.master.earnings") }}
                  </th>
                  <th style="border: 2px solid gray" scope="col"></th>
                  <th style="border: 2px solid gray" scope="col" colspan="3">
                    {{ $t("payroll.master.deductions") }}
                  </th>
                  <th
                    style="border: 2px solid gray"
                    scope="col"
                    rowspan="2"
                  ></th>
                </tr>
              </thead>
              <tbody>
                <tr style="border: 2px solid gray">
                  <th style="border: 2px solid gray">
                    {{ $t("payroll.master.no") }}
                  </th>
                  <th style="border: 2px solid gray">
                    {{ $t("payroll.master.name_of_emp") }}
                  </th>
                  <th
                    style="border: 2px solid gray"
                    v-for="item in salaryItems"
                    :key="item"
                  >
                    {{ item?.name }}
                  </th>
                  <th style="border: 2px solid gray">
                    {{ $t("payroll.master.gross") }}
                  </th>
                  <th
                    style="border: 2px solid gray"
                    v-for="deduction in deductions"
                    :key="deduction"
                  >
                    {{
                      $i18n.locale == "ar"
                        ? deduction?.name
                        : deduction?.name_en
                    }}
                  </th>
                  <th style="border: 2px solid gray">
                    {{ $t("payroll.master.tax") }}
                  </th>
                  <th style="border: 2px solid gray">
                    {{ $t("payroll.master.total_loan") }}
                  </th>
                  <th style="border: 2px solid gray">
                    {{ $t("payroll.master.deductabe_loan") }}
                  </th>
                  <th style="border: 2px solid gray">
                    {{ $t("payroll.master.net") }}
                  </th>
                </tr>
                <tr
                  style="border: 2px solid gray"
                  v-for="(item, index) in masterPayroll"
                  :key="item"
                >
                  <td style="border: 2px solid gray">
                    {{ index + 1 }}
                  </td>
                  <td style="border: 2px solid gray">
                    {{ item?.name }}
                  </td>
                  <td
                    style="border: 2px solid gray"
                    v-for="(salary, i) in salaryItems"
                    :key="salary"
                  >
                    {{
                      item.salary_items == null ? 0 :
                      ( salary.id == item.salary_items[i]?.salary_item_id
                        ? item.salary_items[i]?.value
                          ? item.salary_items[i]?.value?.toLocaleString()
                          : 0
                        : 0
                      )
                    }}
                  </td>
                  <td style="border: 2px solid gray">
                    {{
                      (item?.salary_items) ?
                        item?.salary_items
                        .reduce(
                          (accumulator, salaryItem) =>
                            accumulator +
                            parseFloat(
                              salaryItem?.value ? salaryItem?.value : 0
                            ),
                          0
                        )
                        ?.toLocaleString() : ''
                    }}
                  </td>
                  <td style="border: 2px solid gray">
                    {{ item?.tax }}
                  </td>
                  <td style="border: 2px solid gray">
                    {{ parseFloat(item?.total_loan).toLocaleString() }}
                  </td>
                  <td style="border: 2px solid gray">
                    <input
                      class="money-btn"
                      @change="changeDeduction($event, item)"
                      type="number"
                      :value="
                        item.employeeDeduction ? item.employeeDeduction : 0
                      "
                    />
                  </td>
                  <td style="border: 2px solid gray">
                    {{
                      (
                        parseFloat(item?.total_balance) -
                        parseFloat(
                          item?.employeeDeduction ? item?.employeeDeduction : 0
                        )
                      )?.toLocaleString()
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Start Add Modal-->
  <div
    class="modal fade"
    id="payPayrollModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <form @submit.prevent="payMasterPayrollSalaries()">
          <div class="modal-header">
            <div class="d-flex justify-content-around">
              <!-- <h5 class="modal-title" id="exampleModalLabel">
                {{ $t("emps.pay") }}
              </h5> -->
              <h5 class="modal-title" id="exampleModalLabel">
                {{ $t("payroll.master.master_title") }}
              </h5>
            </div>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              style="margin: 0"
            ></button>
          </div>

          <div class="modal-body">
            <div class="pay-card">
              <div class="mb-4">
                <label for="asset_id">{{
                  $t("emps.payment_details.title")
                }}</label>
                <hr />
              </div>
              <div class="row">
                <div class="col">
                  <label class="d-block">
                    {{ $t("emps.payment_details.from") }}
                  </label>
                  <hr />
                  <label class="d-block">
                    {{ $t("emps.payment_details.amount") }}
                  </label>
                  <hr />

                  <label class="d-block">
                    {{ $t("emps.payment_details.month") }}
                  </label>
                  <hr />

                  <label class="d-block">
                    {{ $t("emps.payment_details.year") }}
                  </label>
                  <hr />
                </div>
                <div class="col">
                  <label class="d-block">
                    {{ paymentMethod?.name }}
                  </label>
                  <hr />

                  <label class="d-block">
                    {{ parseFloat(totalSalary).toLocaleString() }}
                  </label>
                  <hr />

                  <label class="d-block">
                    {{ data.month }}
                  </label>
                  <hr />

                  <label class="d-block">
                    {{ data.year }}
                  </label>
                  <hr />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
            <button
              data-bs-dismiss="modal"
              type="submit"
              class="btn btn-primary"
            >
              {{ $t("emps.pay") }}
            </button>
            <button
              data-bs-dismiss="modal"
              type="button"
              class="btn btn-danger"
            >
              {{ $t("popups.cancel") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!--End Add Modal-->
</template>

<style scoped>
th td tr {
  border: 2px solid !important;
}
.pay-card {
  background: #fafafa;
  margin: 1rem;
  border-radius: 0.5rem;
  padding: 1rem;
}
</style>
