<script>
import appConfig from "@/app.config";
import {Modal} from "bootstrap";

export default {
    page: {
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {},
    data() {
        return {
            salary_items: [],
            month: "",
            existingSalaryMonthes: [],
            SalaryItems: {},
            page: 1,
            limit: 20,
            tot_pages: 0,
            searchSalaryItems: null,
            SearchMode: false,
            emps: [],
            dedcutionTot: 0,
            total_salary: 0,
            total_deduction: 0,
            total: 0,
            branch_id: null,
            payData: {},
            currentAssets: [],
            addAsset: [{}],
            totalSalary: 0,
            remainingSalary: 0,
            date: "",
            descr: "",
            descr_en: "",
            emp_id: null,
            salaryMonth: null,
            salaryYear: null,
            paidBalance: null,
            selectedEmpIndex: null,
            paymentMethods: []

            // appprices:[]
        };
    },
    methods: {
        getPaymentMethods() {
        this.http.get("cash-accounts/company-accounts").then((res) => {
            this.paymentMethods = res.data;
        });
        },
        deleteOption(index) {
            this.addAsset.splice(index, 1);
        },
        generateSalaries() {
            // const year = this.date.split("-")[0];
            console.log({ month: this.month });
            const date = new Date(this.month);
            let month = date.getMonth();
            month++;
            const year = date.getFullYear();
            this.http
                .post("hr-monthly-salaries/generate-salaries", {
                    month,
                    year,
                    descr: this.descr,
                    descr_en: this.descr_en,
                })
                .then(() => {
                    console.log("done");
                    this.month = "";
                    this.descr = "";
                    this.get(this.page);
                    setTimeout(() => {
                        document.querySelector(".btn-close").click();
                    }, 200);
                });
        },
        pay(app, index) {
            this.currentAssets = [];
            this.selectedEmpIndex = index;
            this.emp_id = app.id;
            this.http
                .post("salary-items/salary-data", {
                    code: app.id,
                    branch_id: this.branch_id,
                })
                .then((resp) => {
                    this.currentAssets = resp.data;
                    // this.currentAssets = resp.data[1];
                    this.totalSalary = resp.totalSalary.toLocaleString();
                    console.log("done", this.currentAssets);
                });
            this.payData = app;
            console.log(this.payData);
        },
        prePay(app) {
            this.emp_id = app.emp_id;
            this.salaryMonth = app.month;
            this.salaryYear = app.year;
            this.paidBalance = app.paid_balance;
            this.remainingSalary = app.remaining;
        },
        paySalary() {
            console.log(this.addAsset);
            this.http
                .post("salary-items/pay", {
                    emp_id: this.emp_id,
                    salaryMonth: this.salaryMonth,
                    salaryYear: this.salaryYear,
                    details: this.addAsset,
                    paid_balance: this.paidBalance,
                })
                .then((resp) => {
                    console.log("done");
                    this.totalSalary = resp.data;
                    this.totalSalary = this.totalSalary.toLocaleString();
                    this.addAsset = [{}];
                    this.emps.forEach((emp, index) => {
                        if (index == this.selectedEmpIndex) {
                            emp.totalSalary = resp.data;
                        }
                    });
                    this.get(this.page);
                    this.paySalaryModal.hide();
                });
        },
        getExistingSalaryMonthes() {
            this.http
                .get("hr-monthly-salaries/get-emps-remaining")
                .then((resp) => {
                    if (resp.status) {
                        this.existingSalaryMonthes = resp.data;
                    }
                });
        },
        search() {
            this.SearchMode = true;
            this.tot_pages = 0;
            if (this.searchSalaryItems == "") {
                this.get(this.page);
                return;
            }
            this.http
                .post("emps/search/payroll", {
                    search: this.searchSalaryItems,
                    limit: this.limit,
                    page: this.page,
                    col: "code",
                })
                .then((res) => {
                    this.emps = res.data;
                });
        },
        cancelSearchMode() {
            this.searchSalaryItems = "";
            this.SearchMode = false;
            this.get(this.page);
        },

        get(page) {
            console.log(page);
            this.http
                .post("hr-monthly-salaries/get-emps-remaining", {
                    limit: this.limit,
                    page: page,
                })
                .then((res) => {
                    this.page = page;
                    this.tot_pages = Math.ceil(res.total / this.limit);
                    this.emps = res.data;
                });
        },
    },
    created() {
        this.getPaymentMethods();
        this.get(1);
        // this.getExistingSalaryMonthes();
    },
    mounted() {
    this.paySalaryModal = new Modal("#addModal")
  },
};
</script>

<template>
    <div class="row">
        <div class="card">
            <div class="card-body">
                <div class="row mb-3">
                    <div class="col-6">
                        <div
                            class="search-box chat-search-box"
                            style="width: 250px"
                        >
                            <div class="position-relative">
                                <input
                                    :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
                                    @keyup="search()"
                                    type="text"
                                    class="form-control fa-lg text-light"
                                    :placeholder="$t('salaryItems.search')"
                                    v-model="searchSalaryItems"
                                    style="background-color: #2a3042 !important"
                                />
                                <i
                                    class="bx bx-search-alt search-icon text-light"
                                ></i>
                                <button
                                    @click="cancelSearchMode()"
                                    v-if="SearchMode"
                                    class="btn-close text-light"
                                    style="
                                        position: absolute;
                                        top: 12px;
                                        left: 250px;
                                    "
                                ></button>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <button
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#payModal"
                            class="btn btn-primary float-end my-2"
                        >
                            {{ $t("emps.genrate_salaries") }}
                        </button>
                    </div>
                </div>

                <div class="table-responsive">
                    <table
                        :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                        class="table table-centered table-nowrap table-striped table-hover align-middle"
                    >
                        <thead>
                            <tr
                                class="text-light"
                                style="background-color: #2a3042 !important"
                            >
                                <th scope="col">#</th>
                                <th scope="col">{{ $t("emps.name") }}</th>
                                <th scope="col">{{ $t("emps.remaining") }}</th>
                                <th scope="col">{{ $t("emps.operations") }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(app, index) in emps"
                                :key="app"
                                class=""
                            >
                                <td>{{ index + 1 }}</td>
                                <td>{{ app.emp_name }}</td>
                                <td>{{ app.remaining }}</td>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <button
                                            @click="prePay(app)"
                                            type="button"
                                            data-bs-toggle="modal"
                                            data-bs-target="#addModal"
                                            class="btn btn-primary float-end my-2"
                                        >
                                            {{ $t("emps.pay") }}
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <!-- end  table -->
    <!--Start Pay Modal-->
    <div
        class="modal fade"
        id="payModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <form @submit.prevent="generateSalaries()">
                    <div class="modal-header">
                        <div class="d-flex justify-content-around">
                            <!-- <h5 class="modal-title" id="exampleModalLabel">
                {{ $t("emps.pay") }}
              </h5> -->
                            <h5 class="modal-title" id="exampleModalLabel">
                                {{ $t("emps.genrate_salaries") }}
                            </h5>
                        </div>
                        <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            style="margin: 0"
                        ></button>
                    </div>

                    <div class="modal-body">
                        <div class="row d-flex p-4 justify-content-center">
                            <div class="row mb-2">
                                <label class="inline" for="toJobTitle">{{
                                    $t("payroll.master.month")
                                }}</label>
                                <input
                                    type="month"
                                    class="form-control"
                                    v-model="month"
                                />
                            </div>
                            <div class="row">
                                <label for="#descr">{{
                                    $t("payroll.descr")
                                }}</label>
                                <textarea
                                    class="form-control mb-2 mx-1"
                                    v-model="descr"
                                    cols="30"
                                    rows="4"
                                ></textarea>
                            </div>
                            <div class="row">
                                <label for="#descr">{{
                                    $t("payroll.descr_en")
                                }}</label>
                                <textarea
                                    class="form-control mb-2 mx-1"
                                    v-model="descr_en"
                                    cols="30"
                                    rows="4"
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <div
                        class="modal-footer"
                        :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                    >
                        <button type="submit" class="btn btn-primary">
                            {{ $t("emps.genrate") }}
                        </button>
                        <button
                            data-bs-dismiss="modal"
                            type="button"
                            class="btn btn-danger"
                        >
                            {{ $t("popups.cancel") }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!--End Pay Modal-->

    <!--Start Add Modal-->
    <div
        class="modal fade"
        id="addModal"
        tabindex="-1"
        aria-labelledby="exampleModal"
        aria-hidden="true"
        :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <form @submit.prevent="paySalary()">
                    <div class="modal-header">
                        <div class="d-flex justify-content-around">
                            <!-- <h5 class="modal-title" id="exampleModalLabel">
                {{ $t("emps.pay") }}
              </h5> -->
                            <h5 class="modal-title" id="exampleModal">
                                <span>{{ $t("emps.total") }} : </span>
                                {{ remainingSalary }}
                            </h5>
                        </div>
                        <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            style="margin: 0"
                        ></button>
                    </div>

                    <div class="modal-body">
                        <div class="row d-flex align-items-center">
                            <div class="col">
                                <label for="asset_id">{{
                                    $t("popups.chose_salary_options")
                                }}</label>
                            </div>
                            <div class="col d-flex justify-content-end">
                                <button
                                    v-if="addAsset.length < 3"
                                    type="button"
                                    @click="addAsset.push({})"
                                    class="btn float-end"
                                >
                                    <span
                                        class="bx bxs-plus-circle float-end fa-2x"
                                        style="color: #2a3042 !important"
                                    ></span>
                                </button>
                            </div>
                        </div>
                        <div v-for="(asset, index) in addAsset" :key="asset">
                            <div class="row my-3">
                                <div class="d-flex justify-content-between">
                                    <select
                                        v-model="asset.level_three_chart_of_account_id"
                                        class="form-select mb-2"
                                        id="asset_id"
                                        :placeholder="$t('emps.account')"
                                    >
                                        <option
                                            v-for="assets in paymentMethods"
                                            :key="assets"
                                            :value="assets?.level_three_chart_of_account_id"
                                        >
                                            {{ assets?.name }}
                                        </option>
                                    </select>
                                    <input
                                        v-model="asset.value"
                                        type="number"
                                        class="form-control mb-2 mx-1"
                                        :placeholder="$t('emps.amount')"
                                    />
                                    <span class="mx-2">
                                        <button
                                            class="btn btn-danger"
                                            @click="deleteOption(index)"
                                            v-if="index > 0"
                                        >
                                            <i class="bx bx-trash"></i>
                                        </button>
                                        <button
                                            v-else
                                            disabled
                                            class="btn btn-danger"
                                        >
                                            <i class="bx bx-trash"></i>
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="modal-footer"
                        :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                    >
                        <button
                            :disabled="
                                !addAsset[0]?.hasOwnProperty('asset_id') &&
                                !addAsset[0]?.hasOwnProperty('value')
                            "
                            type="submit"
                            class="btn btn-primary"
                        >
                            {{ $t("emps.pay") }}
                        </button>
                        <button
                            data-bs-dismiss="modal"
                            type="button"
                            class="btn btn-danger"
                        >
                            {{ $t("popups.cancel") }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!--End Add Modal-->

    <!--   Apps  Pagination     -->
    <ul class="pagination pagination-rounded justify-content-center mb-2">
        <li class="page-item" :class="{ disabled: page == 1 }">
            <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(page - 1)"
                aria-label="Previous"
            >
                <i class="mdi mdi-chevron-left"></i>
            </a>
        </li>
        <li
            class="page-item"
            :class="{ active: p == page }"
            v-for="p in tot_pages"
            :key="p"
        >
            <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
                p
            }}</a>
        </li>

        <li class="page-item" :class="{ disabled: page == tot_pages }">
            <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(page + 1)"
                aria-label="Next"
            >
                <i class="mdi mdi-chevron-right"></i>
            </a>
        </li>
    </ul>
    <!--end-->
</template>
